"use client";

import { I18nextProvider } from "react-i18next";
import initTranslations from "@/app/i18n";
import { createInstance } from "i18next";

/**** How to use this Provider if need translations on a ClientComponent:
 *
 * At Parent ServerComponent, call initTranslations.
 *
 * const { t, resources } = await initTranslations(lang, i18nNamespaces);
 *
 * ## At render, wrap ClientComponent with provider:
 *
 * <TranslationsProvider
 *  namespaces={i18nNamespaces}
 *  locale={lang}
 *  resources={resources}>
 *
 *   <ClientComponent />
 *
 * </TranslationsProvider>
 *
 * ## At ClientComponent, call useTranslation hook from react-i18next
 *
 * 'use client';
 * import { useTranslation } from 'react-i18next';
 *  ...
 *    const { t } = useTranslation();
 *  ...
 *
 * */

export default function TranslationsProvider({ children, locale, namespaces, resources }) {
  const i18n = createInstance();

  initTranslations(locale, namespaces, i18n, resources);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}
