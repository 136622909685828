/************** EVENT PROPERTIES ****************/

export enum GTM_EVENT_NAME {
  SIGN_UP_COMPLETED = "signup-completed",
  PURCHASE = "purchase",
  PAGE_VIEW = "page_view",
}

export enum GTM_EVENT_SOURCE {
  MODAL = "sign up modal",
  REGISTER_PAGE = "register page",
}

export enum AMPLITUDE_EVENT_NAME {
  view_landing_home_generator = "view_landing_home_generator",
  view_landing_home = "view_landing_home",
  view_landing_sem = "view_landing_sem",
  view_landing_signup = "view_landing_signup",
  view_landing_login = "view_landing_login",
}

/************** EVENTS ****************/

export type AMPLITUDE_EVENT = {
  name: AMPLITUDE_EVENT_NAME;
  data?: Record<string, any>;
};

export type GTM_EVENT = {
  event: GTM_EVENT_NAME;
  source?: GTM_EVENT_SOURCE;
  purchase?: Record<string, any>;
  page?: string;
};
