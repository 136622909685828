"use client";

import { useEffect } from "react";
import { GTM_EVENT, GTM_EVENT_NAME } from "@/internals/constants/events";
import { usePathname } from "next/navigation";

export const HistoryEvent = () => {
  const pathname = usePathname();
  useEffect(() => {
    window?.dataLayer?.push({
      event: GTM_EVENT_NAME.PAGE_VIEW,
      page: pathname,
    } as GTM_EVENT);
  }, [pathname]);
  return null;
};
