export interface Context {
  [key: string]: any;
  [NAVIGATION_STATE]?: any;
}

export interface GlobalContextProps {
  context: Context;
  setContext: (context: Context) => void;
}

export const CONTEXT = "context";
export const NAVIGATION_STATE = "navigationState";
