"use client";

import React, { createContext, useEffect, useState } from "react";

import { getCookie, setCookie } from "@/services/CookiesService";

import { CONTEXT, GlobalContextProps } from "./types";

export const GlobalContext = createContext<GlobalContextProps>(undefined);

export const GlobalProvider = ({ children }: any) => {
  const [context, setContext] = useState<any>({});

  useEffect(() => {
    if (Object.keys(getCookie(CONTEXT) || []).length === 0) {
      setCookie(CONTEXT, {});
    } else {
      setContext({ ...getCookie(CONTEXT) });
    }
  }, []);

  return (
    <GlobalContext.Provider value={{ context, setContext }}>{children}</GlobalContext.Provider>
  );
};
